export const swedish = {
    translations: {
      //App Sidebar
      login:'logga in',
      logout:'logga ut',
      dashBoard: "DashBoard",
      home: "Hem",
      charts: "Statistik",
      rssFeed: "Nyheter",
      services:'Tjänster',
      partners:'Medarbetare',
      contact: "Kontakta oss",
      videos:"Information",
      question1:"Är någon av de av er uppgivna verkliga huvudmännen eller bolagets styrelseledamöter i politiks utsatt position?",
      question2:'Har bolaget skulder till kronofogden?',
      question3: 'Har bolaget kontanta försäljningar?',
      question4:'Är någon av de av er uppgivna verkliga huvudmännen eller bolagets styrelseledamöter i politiks utsatt position?',
      yes:'Ja',
      no:'Nej',
      UploadDrivingLicense:'Ladda upp godkänt ID-handling',

      //Home Page
      iAgree: 'jag håller med',
       welcome: "VÄLKOMMEN TILL DSS KONSULT",
      headlineTitle: "En Personlig Bokföringsbyrå",
      headlineSubtitle:
        "DSS Konsult är byrån för dig som vill växa som företagare. Tack vare vårt personliga bemötande, starka engagemang och skräddarsydda lösningar har vi bara nöjda kunder. Är du intresserad av att bli vår nästa nöjda kund?",
      headlineButton: "Kontakta oss",
      homeDesription:
        "Om du letar efter en redovisningskonsult och en auktoriserad redovisningsbyrå så har du hittat rätt. Vi hjälper dig med allt inom redovisningstjänster som redovisning och deklaration. Dessutom kan vi bistå med kvalificerad ekonomisk rådgivning, vilket kan innefatta budgetering, skatterådgivning eller affärsutveckling. Inom juridiska tjänster kan vi bistå dig med bodelning, delägarskapsavtal och bouppteckning med mera. Läs gärna vidare under tjänster för att se vad mer vi kan hjälpa dig med. Vi har kontor i Degerfors samt Örebro men har ett kundnätverk som sträcker sig över hela landet.",
      DigitalAccounting: "Digital & Personlig Bokföring",
      DigitalAccountingDescription:
        "Hos DSS konsult får du alltid en engagerad redovisningskonsult som ger dig personlig service i kombination med smarta tekniska lösningar. På så sätt behöver du inte välja mellan en digital tjänst och personlig kontakt; hos oss får du båda delarna. I vår löpande tjänst får du inte bara rätt nummer deklarerat till Skatteverket på rätt dag, utan även en kunnig redovisningskonsult att svara på frågor med. Oavsett hur du ser på ditt företags räkenskaper så har vi säkert en lösning för dig. Självklart helt digitalt, med flexibla system som passar dig och med hög personlig service till lågt pris.",
      WeReduceCosts: "Vi Sänker Dina Redovisningskostnader",
      TestUs: "Låter det otroligt? Prova oss",
      TestUsDescription:
        "Varje företag är unikt men vissa saker är gemensamma för de flesta företag. Därför kan vi ge en bra prisuppskattning för vad våra tjänster skulle kosta ditt företag om du fyller i uppgifterna nedan. Vi återkommer med ett pris inom 24 timmar, och vi lovar att du kommer att gilla det!",
      WeReduceAccountingCost: "Vi Sänker Dina Redovisningskostnader",
      Doesthatsoundincredible: "Låter det otroligt? Prova oss",
      WeReduceAccountingCostDescription:
        "Varje företag är unikt men vissa saker är gemensamma för de flesta företag. Därför kan vi ge en bra prisuppskattning för vad våra tjänster skulle kosta ditt företag om du fyller i uppgifterna nedan. Vi återkommer med ett pris inom 24 timmar, och vi lovar att du kommer att gilla det!",
      ContactUsButton: "Kontakta oss",

      // Sliders Text
      getEstimateTitle: "Få en prisuppskattning",
      numberOfSalaries: "Antal löner per månad",
      numberOfInvoicesPerMonth: "Antal fakturor per månad",
      numberOfReceiptsPerMonth: "Antal kvitton per månad",
      getEstimateButton: "Jag vill ha en prisuppskattning",

      //Charts Page
      dateTitle: "Datum",
      BalanceCounting: "Balansräkning",
      ResultCounting: "Resultaträkning",
      AccountsTitle: "Konton",
      StartDate: "Startdatum",
      EndDate: "Slutdatum",
      Balcance: "Balans",
      GeneratePdf: "Generera PDF",
      Loading: "Laddar...",

      //Feeds Page
      AdminFeeds: "DSS Konsult Feeds",

      // ServicesPage
      service1Heading:'Ekonomi',
      service1Details:"För din löpande bokföring skräddarsys ett paket efter dina behov. Vi tar hand om din bokföring från faktura till färdigt bokslut, gör skattedeklarationen för moms och arbetsgivaravgifter och hjälper till med din löneadministration. Många företagare har valt att anlita oss i sin redovisning och administration. Därigenom kan de ägna mer tid och kraft till sin kärnverksamhet. När räkenskapsåret är slut gör vi bokslut. I bokslutet blir alla uppgifter definitiva och här bestäms bland annat avskrivningar och övriga bokslutsdispositioner som direkt påverkar ditt årsresultat. Vill ni flytta eller byta till oss så är det smidigt. Vi arbetar med klientbaserade och webbaserade ekonomisystem och via fil kan vi få in dina uppgifter på ett snabbt, enkelt och effektivt sätt.",
      service2Heading: 'Skatt',
      service2Details:'"Vi gör både företagets och din privata deklaration. Sköter du själv din löpande redovisning kan vi hjälpa till att upprätta er deklaration och eventuellt bokslut. I samråd med dig upprättas er deklaration. Ni får sedan en skatteuträkning som även innehåller eventuell utdelning och vi ser till att deklarationen kommer in till Skatteverket. Med lång erfarenhet och bred kompetens hjälper vi även jord och skogsägare, med och utan djurhållning, med bokslut och deklarationer. Du behöver bara ta med dig räkenskaperna och deklarationsmaterialet, så upprättar vi i samråd med dig, din och näringens deklaration. Du får en kopia på deklarationsblanketterna med skatteuträkning. Efter ditt medgivande sänds deklarationen till Skatteverket."',
      service3Heading: 'Rådgivning',
      service3Details:"Det är många faktorer som man ställs inför som företagare och du kan få kvalificerad hjälp till att reda ut din situation. Starta eget? Vi hjälper till med rådgivning och upprättande av bolag. Vid aktiebolagsbildningar behövs en hel del dokument och registreringshandlingar som vi hjälper till att ta fram. Vi kan även hjälpa till med finnansering genom företagslån hos olika banker som vi har kontakt med. Aktieutdelning eller löneuttag som bolagsägare? Pensionsförsäkringar? Firmabil eller skattefri bilersättning? Bilförmån? Skjuta upp och mildra skatteeffekten genom avsättning till periodiseringsfond, expansionsmedel och räntefördelning. Det är många faktorer som man ställs inför som företagare och vi hjälper till att reda ut din situation. Genom vår auktorisation hos SRF, Sveriges Redovisningskonsulters Förbund, och långa erfarenhet kan du vara trygg med att våra tjänster håller högsta klass.",

      //Contact Us Page
      ContactUsTitle: "Välkommen till En smartare redovisningsbyrå!",
      ContactUsSubtitle:
        "Vi står redo att svara på dina frågor, stora som små.",
      MailingAddress: "Postadress",
      PhoneNumber: "Telefonnummer",
      SendInquiry: "Skicka en förfråga",
      YourName: "Ditt namn (obligatoriskt)",
      YourEmail: "Din e-post (obligatoriskt)",
      YourMessage: "Ditt meddelande",
      AccepitPolicyTest:
        "Genom att fortsätta accepterar jag Dss konsults dataskyddspolicy.",
      SendButton: "Skicka",
    },
  }