import { arabic } from "./languages/arabic";
import { english } from "./languages/english"
import { kurdish } from "./languages/kurdish";
import { swedish } from "./languages/swedish";
export const translations = {
  //Engleska
  en: english,

  //Kurdiska
  ku: kurdish,

  //Arabiska
  ar:arabic,

  //Svenska
  sv: swedish
};
