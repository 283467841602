import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom'
import './scss/style.scss'
import OneSignal from 'react-onesignal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Landing = React.lazy(() => import('./views/pages/landing/Landing'))

const App = () => {
  const notify = (message) =>
    toast.info(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  useEffect(() => {

    OneSignal.init({
      appId: '6d20395f-3945-4a16-a8ed-39e409d65f85',
      allowLocalhostAsSecureOrigin: true,
    }).then(() => {
      OneSignal.sendTag("language", localStorage.getItem("i18nextLng") === "en-GB"? "en" : localStorage.getItem("i18nextLng")  || "en", function(tagsSent) {

        // Callback called when tags have finished sending
        console.log(tagsSent);
      });
 
    })
    OneSignal.addListenerForNotificationOpened(() => {
      console.log('hi')
    })

    OneSignal.on('addListenerForNotificationOpened', function (event) {
      console.log('Notification Clicked', event)
    })

    OneSignal.on('notificationDisplay', function (event) {
      console.log('Notification Displayed', event)
      // notify(`${event.heading}: ${event.content}`)
      console.log('OneSignal notification displayed:', event)
    })
    OneSignal.on('notificationDismiss', function (event) {
      console.log('OneSignal notification dismissed:', event)
    })
    // OneSignal.registerForPushNotifications()
  }, [])

  // componentDidMount() {
  //   OneSignal.init({
  //     appId: '6d20395f-3945-4a16-a8ed-39e409d65f85',
  //   })
  //   OneSignal.isPushNotificationsEnabled((isEnabled) => {
  //     console.log('Push notifications are enabled!', isEnabled)
  //   })
  // }

  return (
    <HashRouter basename='/'>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          <Route exact path="/landing" name="Landing" render={(props) => <Landing {...props} />} />
        </Switch>
      </React.Suspense>
      <ToastContainer />
    </HashRouter>
  )
}
export default App
