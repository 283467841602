export const arabic = {
    translations: {
      //App Sidebar
      login:'تسجيل الدخول',
      logout:'تسجيل خروج',
      dashBoard:"بالصفحالصفحة الرئيسية",
      home: "بداية",
      charts: "إحصائيات",
      rssFeed: "أخبار",
      services:'خدمات',
      partners:'زميل عمل',
      contact: "اتصال",
      videos:"معلومة",
      question1:"هل أحد من المسؤولين الرئيسيين أو أعضاء مجلس أدارة الشركة في موقف سياسي ضعيف؟" ,
      question2:'هل الشركة عليها ديون لشركة Kronofogden',
      question3:'هل لدى الشركة مبيعات نقدية؟',
      question4: "هو أحد المبادئ الحقيقية التي ذكرتها أنت أو الشركة  أعضاء مجلس الإدارة في موقف ضعيف للسياسة؟",
      yes:'نعم',
      no:'لا',
      UploadDrivingLicense:'تحميل رخصة القيادة',

      //Home Page
      iAgree: 'أنا موافق',
      welcome: "DSS Konsult مرحبا بك في",
      headlineTitle: "شركة محاسبة شخصية",
      headlineSubtitle: "DSS Konsult هي الوكالة التي تريد أن تنمو كرائد أعمال. بفضل نهجنا الشخصي والتزامنا القوي والحلول المصممة خصيصًا ، لدينا عملاء راضون فقط. هل أنت مهتم بأن تصبح عميلنا القادم الراضي؟",
      headlineButton: "اتصل بنا",
      homeDesription: "",
      DigitalAccounting: "المحاسبة الرقمية والشخصية",
      DigitalAccountingDescription: "في DSS Consultant ، تحصل دائمًا على مستشار محاسبة مخصص يقدم لك خدمة شخصية جنبًا إلى جنب مع الحلول التقنية الذكية. بهذه الطريقة ، لا يتعين عليك الاختيار بين الخدمة الرقمية والاتصال الشخصي ؛ معنا تحصل على كلا الجزأين. في خدمتنا المستمرة ، لا تحصل فقط على الرقم الصحيح المعلن لمصلحة الضرائب السويدية في اليوم المناسب ، ولكن أيضًا تحصل على مستشار محاسبة واسع المعرفة للإجابة على الأسئلة. بغض النظر عن الطريقة التي تنظر بها إلى حسابات شركتك ، فنحن على يقين من أن لدينا حلًا لك. بالطبع رقمي بالكامل ، مع أنظمة مرنة تناسبك ومستوى عالٍ من الخدمة الشخصية وبسعر منخفض.",
      WeReduceCosts: "نخفض تكاليف المحاسبة الخاصة بك",
      TestUs: "اختبر خدماتنا",
      TestUsDescription: "هەر کۆمپانیایەک تایبەتە بەڵام هەندێک شت هاوبەشن بۆ زۆربەی کۆمپانیاکان. بۆیە دەتوانین خەمڵاندنی نرخێکی باش بدەین بۆ ئەوەی خزمەتگوزارییەکانمان چی تێدەچێت بۆ کۆمپانیاکەتان ئەگەر زانیارییەکانی خوارەوە پڕبکەیتەوە. لە ماوەی ٢٤ کاتژمێردا بە نرخێک دەگەڕێینەوە، وە بەڵێن دەدەین بەدڵتان بێت!",
      WeReduceAccountingCost: "نخفض تكاليف المحاسبة الخاصة بك",
      Doesthatsoundincredible: "هل هذا الصوت لا يصدق؟ اختبرنا",
      WeReduceAccountingCostDescription: "كل شركة فريدة من نوعها ولكن بعض الأشياء مشتركة في معظم الشركات. لذلك ، يمكننا تقديم تقدير سعر جيد لما ستكلفه خدماتنا لشركتك إذا قمت بملء المعلومات أدناه. سنعود بسعر في غضون 24 ساعة ، ونعدك بإعجابك!",
      ContactUsButton: "اتصل بنا",

      // Sliders Text
      getEstimateTitle: "احصل على تقدير سعر",
      numberOfSalaries: "كم رواتب في الشهر",
      numberOfInvoicesPerMonth: "كم عدد الفواتير في الشهر",
      numberOfReceiptsPerMonth: "كم عدد الإيصالات في الشهر",
      getEstimateButton: "اريد تقدير السعر",

      //Charts Page
      dateTitle: "تاريخ",
      BalanceCounting: "ورقة التوازن",
      ResultCounting: "قوائم الدخل",
      AccountsTitle: "حساب شخصي",
      StartDate: "تاريخ البدء",
      EndDate: "تاريخ الانتهاء",
      Balcance: "الرصيد",
      GeneratePdf: "يقوم بإنشاء ملف",
      Loading: "تحميل الملف",

      //Feeds Page
      AdminFeeds: "الخلاصات الادارية",
      // ServicesPage
      service1Heading:'Ekonomi',
      service1Details:"För din löpande bokföring skräddarsys ett paket efter dina behov. Vi tar hand om din bokföring från faktura till färdigt bokslut, gör skattedeklarationen för moms och arbetsgivaravgifter och hjälper till med din löneadministration. Många företagare har valt att anlita oss i sin redovisning och administration. Därigenom kan de ägna mer tid och kraft till sin kärnverksamhet. När räkenskapsåret är slut gör vi bokslut. I bokslutet blir alla uppgifter definitiva och här bestäms bland annat avskrivningar och övriga bokslutsdispositioner som direkt påverkar ditt årsresultat. Vill ni flytta eller byta till oss så är det smidigt. Vi arbetar med klientbaserade och webbaserade ekonomisystem och via fil kan vi få in dina uppgifter på ett snabbt, enkelt och effektivt sätt.",
      service2Heading: 'Skatt',
      service2Details:'"Vi gör både företagets och din privata deklaration. Sköter du själv din löpande redovisning kan vi hjälpa till att upprätta er deklaration och eventuellt bokslut. I samråd med dig upprättas er deklaration. Ni får sedan en skatteuträkning som även innehåller eventuell utdelning och vi ser till att deklarationen kommer in till Skatteverket. Med lång erfarenhet och bred kompetens hjälper vi även jord och skogsägare, med och utan djurhållning, med bokslut och deklarationer. Du behöver bara ta med dig räkenskaperna och deklarationsmaterialet, så upprättar vi i samråd med dig, din och näringens deklaration. Du får en kopia på deklarationsblanketterna med skatteuträkning. Efter ditt medgivande sänds deklarationen till Skatteverket."',
      service3Heading: 'Rådgivning',
      service3Details:"Det är många faktorer som man ställs inför som företagare och du kan få kvalificerad hjälp till att reda ut din situation. Starta eget? Vi hjälper till med rådgivning och upprättande av bolag. Vid aktiebolagsbildningar behövs en hel del dokument och registreringshandlingar som vi hjälper till att ta fram. Vi kan även hjälpa till med finnansering genom företagslån hos olika banker som vi har kontakt med. Aktieutdelning eller löneuttag som bolagsägare? Pensionsförsäkringar? Firmabil eller skattefri bilersättning? Bilförmån? Skjuta upp och mildra skatteeffekten genom avsättning till periodiseringsfond, expansionsmedel och räntefördelning. Det är många faktorer som man ställs inför som företagare och vi hjälper till att reda ut din situation. Genom vår auktorisation hos SRF, Sveriges Redovisningskonsulters Förbund, och långa erfarenhet kan du vara trygg med att våra tjänster håller högsta klass.",


      //Contact Us Page
      ContactUsTitle: "مرحبًا بكم في شركة محاسبة أذكى!",
      ContactUsSubtitle: "نحن جاهزون للإجابة على أسئلتك ، الكبيرة والصغيرة",
      MailingAddress: "العنوان",
      PhoneNumber: "رقم الهاتف",
      SendInquiry: "إرسال استفسار",
      YourName: "اسمك",
      YourEmail: "البريد الإلكتروني",
      YourMessage: "رسائلك",
      AccepitPolicyTest: "قبول اختبار نهج الصفحة",
      SendButton: "إرسال",
    },
  }