export const kurdish = {
    translations: {
      //App Sidebar
      login:'چونه‌ ژووره‌وه‌',
      logout:'چوونە دەرەوە',
      dashBoard: "دەستپێکردن",
      home: "ماڵەوە ",
      charts: "ئامارەکان ",
      rssFeed: "هەواڵ",
      services:'خزمەتگوزارییەکان',
      partners:'هاوکارەکەی',
      contact: "پەیوەندی ",
      videos:"زانیاری",
      question1:"ئایا هیچ خاوەنێک لە کۆمپانیاکەدا پێگەی سیاسیش هەیە؟",
      question2:'ئایا کۆمپانیاکە قەرزاری کرونوفوگدن',
      question3:'ئایا کۆمپانیاکە فرۆشتنی کاشی هەیە؟',
      question4:"ئایا یەکێکە لە بنەما ڕاستەقینەکان کە لەلایەن تۆوە یان کۆمپانیاکە ئاماژەی پێکراوە  ئەندامانی دەستەی کارگێڕی لە پێگەی لاوازی سیاسەتدا؟",
      yes:'بەڵێ',
      no:'نەخێر',
      UploadDrivingLicense:'مۆڵەتی شۆفێری بارکردن',
      //Home Page
      iAgree: 'هاوڕام',
      welcome: "بەخێربێیت بۆ DSS Konsult",
      headlineTitle: "کۆمپانیای ژمێریاری تایبەت",
      headlineSubtitle: "شارةزاي جةند سالةي كومبانياكةمان، ئةبيتة مايةي سةركةوتني تو DSS Konsult",
      headlineButton: "پەیوەندیمان پێوە بکە",
      homeDesription: "",
      DigitalAccounting: "ژمێریاری دیجیتاڵی و کەسی",
      DigitalAccountingDescription: "لە ڕاوێژکاری DSS هەمیشە ڕاوێژکاری ژمێریاری تایبەتمەند بەدەست دەهێنیت کە خزمەتگوزاری کەسیت پێدەدات لەگەڵ چارەسەری تەکنیکی زیرەک. بەم شێوەیە، پێویست ناکات لە نێوان خزمەتگوزارییەکی دیجیتاڵی و پەیوەندی کەسیدا هەڵبژێریت؛ لەگەڵ ئێمە هەردوو بەشەکەت دەست دەکەوێت. لە خزمەتگوزارییە بەردەوامەکەماندا، تۆ نەک هەر ژمارەیەکی گونجاو بەدەست دەهێنیت کە لە ڕۆژی گونجاودا بۆ دەزگای باجی سوید ڕاگەیەندراوە، بەڵکو ڕاوێژکارێکی ژمێریاری شارەزا بۆ وەڵامدانەوەی پرسیارەکان لەگەڵیدا بەدەست دەهێنیت. گرنگ نییە چۆن سەیری ئەکاونتی کۆمپانیاکەتان دەکەیت، دڵنیاین چارەسەرێکمان هەیە بۆت. بێگومان تەواو دیجیتاڵ، لەگەڵ سیستەمی نەرم و نیان کە لەگەڵ تۆدا دەگونجێت و بە ئاستێکی بەرزی خزمەتگوزاری کەسی بە نرخێکی هەرزان.",
      WeReduceCosts: "ئێمە تێچووی ژمێریاریتان کەم دەکەینەوە",
      TestUs: "ئایا ئەوە دەنگێکی باوەڕپێنەکراو دەدات؟ تاقیمان بکەنەوە",
      TestUsDescription: "هەر کۆمپانیایەک تایبەتە بەڵام هەندێک شت هاوبەشن بۆ زۆربەی کۆمپانیاکان. بۆیە دەتوانین خەمڵاندنی نرخێکی باش بدەین بۆ ئەوەی خزمەتگوزارییەکانمان چی تێدەچێت بۆ کۆمپانیاکەتان ئەگەر زانیارییەکانی خوارەوە پڕبکەیتەوە. لە ماوەی ٢٤ کاتژمێردا بە نرخێک دەگەڕێینەوە، وە بەڵێن دەدەین بەدڵتان بێت!",
      WeReduceAccountingCost: "نخفض تكاليف المحاسبة الخاصة بك",
      Doesthatsoundincredible: "ئایا ئەوە دەنگێکی باوەڕپێنەکراو دەدات؟ تاقیمان بکەنەوە",
      WeReduceAccountingCostDescription: "هەر کۆمپانیایەک تایبەتە بەڵام هەندێک شت هاوبەشن بۆ زۆربەی کۆمپانیاکان. بۆیە دەتوانین خەمڵاندنی نرخێکی باش بدەین بۆ ئەوەی خزمەتگوزارییەکانمان چی تێدەچێت بۆ کۆمپانیاکەتان ئەگەر زانیارییەکانی خوارەوە پڕبکەیتەوە. لە ماوەی ٢٤ کاتژمێردا بە نرخێک دەگەڕێینەوە، وە بەڵێن دەدەین بەدڵتان بێت!",
      ContactUsButton: "پەیوەندیمان پێوە بکە",

      // Sliders Text
      getEstimateTitle: "  بەدەستهێنانی نرخ",
      numberOfSalaries: "مانگانەچەند موچەیەک ",
      numberOfInvoicesPerMonth: "چەند پسوڵە لە مانگێکدا ",
      numberOfReceiptsPerMonth: "چەند ڕەچەڵەک لە مانگێکدا ",
      getEstimateButton: "نرخم دەوێت ",

      //Charts Page
      dateTitle: "بەروار",
      BalanceCounting: "پەڕەی هاوسەنگی",
      ResultCounting: "بەیاننامەی داهات ",
      AccountsTitle: "تایبەت",
      StartDate: "بەرواری دەستپێکردن ",
      EndDate: "بەرواری کۆتایی",
      Balcance: "هاوسەنگی",
      GeneratePdf: "PDF پێکبهێنە",
      Loading: "داگرتنی فایلەکە",

      //Feeds Page
      AdminFeeds: "خاوەنی وێبسایتەکە",

      // ServicesPage
      service1Heading:'ئابووری',
      service1Details:"بۆ ژمێریاری، پاکێجێک بەپێی پێداویستییەکانتان داڕێژراوە. ئێمە ئاگاداری ژمێریاری تۆ دەبین لە فاکتورەوە تا بەیاننامەی دارایی کۆتایی، باج بۆ زێدەباری و بەشدارییەکانی خاوەنکار دەکەین و یارمەتی کارگێڕی مووچەکەت دەدەین. زۆرێک لە خاوەنکارەکان هەڵیانبژاردووە کە ئێمە بەکرێ بگرن بۆ ژمێریاری و کارگێڕی خۆیان. ئەمەش ڕێگەیان پێدەدات کات و وزەی زیاتر بۆ کارە سەرەکییەکانیان تەرخان بکەن. کاتێک ساڵی دارایی تەواو بوو، حسابەکان دادەخەین. لە بەیاننامە داراییەکاندا، هەموو زانیارییەکان دەبنە یەکلاکەرەوە و لێرەدا لەنێو شتەکانی تردا، دابەزینی بەها و مامەڵەی دیکەی بەیاننامە داراییەکان کە ڕاستەوخۆ کاریگەرییان لەسەر ئەنجامی ساڵانەت هەیە، دیاری دەکرێن. ئەگەر دەتەوێت بگوازیتەوە یان بگۆڕیت بۆ ئێمە، ئەوە ئاسانە. ئێمە لەگەڵ سیستەمی دارایی لەسەر بنەمای کڕیار و وێب کاردەکەین و لە ڕێگەی فایلەوە دەتوانین داتاکانتان بە خێرایی و بە ئاسانی و بگوازرێتەوە بۆ لای ئێمە.",
      service2Heading: 'باج',
      service2Details:'ئێمە هەم بەیاننامەی کۆمپانیاکە و هەم بەیاننامەی تایبەتی ئێوە دەکەین. ئەگەر خۆت ئاگاداری ژمێریاری خۆت بیت، دەتوانین یارمەتیت بدەین لە ئامادەکردنی بەیاننامە و لەوانەیە بەیاننامە داراییەکانت. بە ڕاوێژ لەگەڵ تۆ بەیاننامەکەت دادەڕێژرێت. پاشان بەیاننامەی باجت پێدەگات کە هەر پاداشتێکیش لەخۆدەگرێت و ئێمە دڵنیا دەبینەوە لەوەی کە بەیاننامەکە لەلایەن دەزگای باجی سویدەوە وەرگیراوە. بە ئەزموونی درێژخایەن و لێهاتوویی فراوان، هەروەها یارمەتی خاوەن زەوی و دارستانەکان دەدەین، بە ئاژەڵداری و بەبێ بەخێوکردنی ئاژەڵ، بە بەیاننامە و بەیاننامە داراییەکان. تەنها پێویستە ئەکاونتەکان و مادەی ڕاگەیاندنەکە لەگەڵ خۆت بهێنیت، ئێمەش بەیاننامەکەت و بەیاننامەی پیشەسازی بە ڕاوێژ لەگەڵ تۆ دادەڕێژین. کۆپییەکی فۆڕمی بەیاننامە لەگەڵ حیسابکردنی باج وەردەگریت. دوای ڕەزامەندی ئێوە، بەیاننامەکە دەنێردرێت بۆ دەزگای باجی سوید.',
      service3Heading: 'ڕاوێژکاری',
      service3Details:"زۆر هۆکار هەن کە وەک خاوەنکارێک ڕووبەڕوویان دەبیتەوە و دەتوانیت یارمەتی شایستە وەربگریت بۆ چارەسەرکردنی دۆخەکەت. دەست بە خۆت بکە؟ ئێمە هاوکار دەبین لە ڕاوێژکاری و دامەزراندنی کۆمپانیاکان. لە کاتی پێکهێنانی کۆمپانیایەکی سنوورداردا، بەڵگەنامە و بەڵگەنامەی تۆمارکردنی زۆر پێویستە، کە ئێمە یارمەتیدەرین بۆ ئامادەکردن. هەروەها دەتوانین یارمەتی دارایی بدەین لە ڕێگەی قەرزی بازرگانی لە بانکە جیاوازەکان کە پەیوەندیمان لەگەڵیان هەیە. پاداشتی پشک یان وەرگرتنی مووچە وەک خاوەن کۆمپانیا؟ بیمەی خانەنشینی؟ ئۆتۆمبێلی کۆمپانیا یان دەرماڵەی ئۆتۆمبێلی بێ باج؟ سوودی ئۆتۆمبێل؟ دواخستن و کەمکردنەوەی کاریگەری باج لە ڕێگەی تەرخانکردن بۆ سندوقێکی کۆکردنەوە، سندوقەکانی فراوانکردن و دابەشکردنی سوود. زۆر هۆکار هەن کە تۆ وەک خاوەنکارێک ڕووبەڕوویان دەبیتەوە، ئێمەش یارمەتیدەر دەبین بۆ چارەسەرکردنی دۆخەکەت. هەروەها لەلایەن کۆنفیدراسیۆنی ژمێریارانی سویدەوە ڕێگەمان پێدراوە. ئەندامێتی ئێمە لەگەڵیان گەرەنتی ئەوەت پێدەدات کە ئێمە کارەکانت بە باشترین شێوە ئەنجام دەدەین.",

      //Contact Us Page
      ContactUsTitle: "بەخێربێیت بۆ کۆمپانیایەکی ژمێریاری زیرەکتر",
      ContactUsSubtitle: "ئێمە ئامادەین وەڵامی پرسیارەکانت بدەینەوە بچووک و گەورە",
      MailingAddress: "ناونیشان",
      PhoneNumber: "ژمارەی تەلەفۆن",
      SendInquiry: "ناردنی داواکاری بەردەنگ",
      YourName: "ناوت ",
      YourEmail: "ئیمەیڵ  ",
      YourMessage: " پەیامەکەت",
      AccepitPolicyTest: "بۆ بەردەوام بوون، من ڕازیم بە یاساکانی ڕێنمایی پاراستنی داتا ",
      SendButton: "بینێرە",
    },
  }