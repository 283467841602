export const english = {
    translations: {
      //App Sidebar
      login:'Login',
      logout:'Logout',
      dashBoard: "DashBoard",
      home: "Home",
      charts: "Statistics",  
      rssFeed: "News",
      services:'Services',
      partners:'Partners',
      contact: "Contact",
      videos:"Information",
      question1:"Is somebody of the real owner stated by you or the company's board members in a vulnerable position as politics?",
      question2:'Does the company have debts to the Kronofogden?',
      question3:'Does the company have cash sales?',
      question4:"Is one of the real principals stated by you or the company's board members in the vulnerable position of politics?",
      yes:'Yes',
      no:'No',
      UploadDrivingLicense:'Upload approved ID document',
      
      //Home Page
      iAgree: 'I Agree',
      welcome: "WELCOME TO DSS CONSULTANT",
      headlineTitle: "A Personal Accounting Agency",
      headlineSubtitle:
        "DSS Konsult is the agency for you who want to grow as an entrepreneur. Thanks to our personal approach, strong commitment and tailor-made solutions, we only have satisfied customers. Are you interested in becoming our next satisfied customer?",
      headlineButton: "Contact Us",
      homeDesription:
        "If you are looking for an accounting consultant and a certified accounting firm then you have found the right one. We help you with everything in accounting services such as accounting and declaration. In addition, we can assist with qualified financial advice, which can include budgeting, tax advice or business development. In legal services, we can assist you with division of property, partnership agreements and estate records and more. Feel free to read further under services to see what more we can help you with. We are located in Degerfors, which is located between Karlstad and Örebro but has a customer network that extends throughout the country.",
      DigitalAccounting: "Digital & Personal Accounting",
      DigitalAccountingDescription:
        "At DSS consultant, you always get a dedicated accounting consultant who gives you personal service in combination with smart technical solutions. This way, you do not have to choose between a digital service and personal contact; with us you get both parts. In our ongoing service, you not only get the right number declared to the Swedish Tax Agency on the right day, but also a knowledgeable accounting consultant to answer questions with. No matter how you look at your company's accounts, we are sure to have a solution for you. Of course completely digital, with flexible systems that suit you and with a high level of personal service at a low price.",
      WeReduceCosts: "We Reduce Your Accounting Costs",
      TestUs: "Does that sound incredible? Test us",
      TestUsDescription:
        "Each company is unique but some things are common to most companies. Therefore, we can give a good price estimate for what our services would cost your company if you fill in the information below. We will return with a price within 24 hours, and we promise you will like it!",
      WeReduceAccountingCost: "We Reduce Your Accounting Costs",
      Doesthatsoundincredible: "Does that sound incredible? Test us",
      WeReduceAccountingCostDescription:
        "Each company is unique but some things are common to most companies. Therefore, we can give a good price estimate for what our services would cost your company if you fill in the information below. We will return with a price within 24 hours, and we promise you will like it!",
      ContactUsButton: "Contact Us",

      // Sliders Text
      getEstimateTitle: "Get A Price Estimate",
      numberOfSalaries: "Number of salaries per month",
      numberOfInvoicesPerMonth: "Number of invoices per month",
      numberOfReceiptsPerMonth: "Number of receipts per month",
      getEstimateButton: "I want a price estimate",

      //Charts Page
      dateTitle: "Date",
      BalanceCounting: "Balance Counting",
      ResultCounting: "Result Counting",
      AccountsTitle: "Accounts",
      StartDate: "Start Date",
      EndDate: "End Date",
      Balcance: "Balance",
      GeneratePdf: "Generate PDF",
      Loading: "Loading",

      //Feeds Page
      AdminFeeds: "DSS Konsult Feeds",
      // ServicesPage
      service1Heading:'Ekonomi',
      service1Details:"För din löpande bokföring skräddarsys ett paket efter dina behov. Vi tar hand om din bokföring från faktura till färdigt bokslut, gör skattedeklarationen för moms och arbetsgivaravgifter och hjälper till med din löneadministration. Många företagare har valt att anlita oss i sin redovisning och administration. Därigenom kan de ägna mer tid och kraft till sin kärnverksamhet. När räkenskapsåret är slut gör vi bokslut. I bokslutet blir alla uppgifter definitiva och här bestäms bland annat avskrivningar och övriga bokslutsdispositioner som direkt påverkar ditt årsresultat. Vill ni flytta eller byta till oss så är det smidigt. Vi arbetar med klientbaserade och webbaserade ekonomisystem och via fil kan vi få in dina uppgifter på ett snabbt, enkelt och effektivt sätt.",
      service2Heading: 'Skatt',
      service2Details:'"Vi gör både företagets och din privata deklaration. Sköter du själv din löpande redovisning kan vi hjälpa till att upprätta er deklaration och eventuellt bokslut. I samråd med dig upprättas er deklaration. Ni får sedan en skatteuträkning som även innehåller eventuell utdelning och vi ser till att deklarationen kommer in till Skatteverket. Med lång erfarenhet och bred kompetens hjälper vi även jord och skogsägare, med och utan djurhållning, med bokslut och deklarationer. Du behöver bara ta med dig räkenskaperna och deklarationsmaterialet, så upprättar vi i samråd med dig, din och näringens deklaration. Du får en kopia på deklarationsblanketterna med skatteuträkning. Efter ditt medgivande sänds deklarationen till Skatteverket."',
      service3Heading: 'Rådgivning',
      service3Details:"Det är många faktorer som man ställs inför som företagare och du kan få kvalificerad hjälp till att reda ut din situation. Starta eget? Vi hjälper till med rådgivning och upprättande av bolag. Vid aktiebolagsbildningar behövs en hel del dokument och registreringshandlingar som vi hjälper till att ta fram. Vi kan även hjälpa till med finnansering genom företagslån hos olika banker som vi har kontakt med. Aktieutdelning eller löneuttag som bolagsägare? Pensionsförsäkringar? Firmabil eller skattefri bilersättning? Bilförmån? Skjuta upp och mildra skatteeffekten genom avsättning till periodiseringsfond, expansionsmedel och räntefördelning. Det är många faktorer som man ställs inför som företagare och vi hjälper till att reda ut din situation. Genom vår auktorisation hos SRF, Sveriges Redovisningskonsulters Förbund, och långa erfarenhet kan du vara trygg med att våra tjänster håller högsta klass.",


      //Contact Us Page
      ContactUsTitle: "Welcome to A Smarter Accounting Agency!",
      ContactUsSubtitle:
        "We are ready to answer your questions, big and small.",
      MailingAddress: "Mailing Address",
      PhoneNumber: "Phone Number",
      SendInquiry: "Send A Contact Inquiry",
      YourName: "Your Name (required)",
      YourEmail: "Your Email (required)",
      YourMessage: "Your Message",
      AccepitPolicyTest:
        "By continuing, I accept Dss konsult data protection policy.",
      SendButton: "Send",
    },
  }